.MainHeader {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #40413f;
  height: 38px;
}

/* Menu button to access left sidebar */
.MenuIconContainer {
  position: relative;
  width: 38px;
  display: inline-block;
  background: #1e1f1e;
  padding-top: 10px;
}

.MenuIcon {
  font-size: 17px;
  color: #ffffffe3;
  padding-left: 10px;
}

.SideBar {
  background-color: #1d1d1d !important;
  border: none !important;
  width: 220px !important;
}
/* ************ */

.ComwattLogoContainer {
  position: absolute;
  left: 38px;
  top: 3px;
}

.ComwattLogoContainer img {
  width: 42%;
  margin: 3px 10px 4px 10px;
}

.IconHelp {
  margin-top: 2px;
}
.IconHelp > a {
  color: #97bf0d;
  font-size: 22px;
  line-height: 38px;
  padding: 20px 7px;
  text-decoration: none;
}
.IconHelp > a:hover {
  color: white;
  text-decoration: none;
}

