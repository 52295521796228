.UnitMesureFilter{
  padding: 10px;
  margin-left: 5px;;
}

.UnitMesureFilter button {
  font-size: 10px;
}

.UnitMesureFilter .menuFilters {
  text-align: left!important;
}

.footNoteInsideChart{
  font-size: 12px;
  position: relative;
  bottom: 4px;
  right: 3px;
}

@media screen and (max-width: 460px) {
  .dataExportButton {
    display: none;
  }
}