.noTilesMessage{
    background-color: #F6F6F6;
    padding: 30px;
}

.noTilesImage{
    width: 120px;
}

.noTilesDescription{
    margin-top: 20px;
    font-size: 13px;
}