.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  width: 65px;
  height: 25px;
  margin: 0 0 0 2em;
  overflow: hidden;
  border-radius: 2px;
}
@media only screen and (max-width: 768px) {
  .button {
    margin: 0;
  }
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #e5e5e5;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  z-index: 1;
}

.button .knobs:before,
.button .knobs:after,
.button .knobs span {
  position: absolute;
  height: 25px;
  font-size: 10px;
  font-weight: bold;
  line-height: 0.9;
  padding: 9px 2px;
  border-radius: 2px;
  transition: 0.3s ease all;
}

.button .knobs:before,
.button .knobs:after {
  color: #4e4e4e;
  z-index: 1;
}

.button .knobs:before {
  content: "NON";
  left: 3px;
}

.button .knobs:after {
  content: "OUI";
  right: 6px;
}

.button .knobs span {
  width: 30px;
  left: 35px;
  background-color:#6d6d6d;
  z-index: 2;
  color: #e5e5e5;
}

.button .checkbox:checked + .knobs span {
  left: 0px;
  background-color: #97bf0d;
  color: #fff;
}

.button .checkbox:checked ~ .layer {
  background-color:  #fff;
}