.modalTile {
    width: 50%;
}

@media only screen and (max-width: 768px) {
    .modalTile {
        width: 90% !important;
    }
}

.socialNetworkChoiceModal {
    width: 100%;
    background: #f0f0f0;
    box-shadow: 0 2px 3px lightgrey;
    color: grey;
    padding: 5px;
    z-index: 1100;
}

.closeSocialNetworkChoiceModal {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}
.closeSocialNetworkChoiceModal i {
    font-size: 8px;
}

.socialNetworkIconsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 10px;
}

.socialNetworkIconsContainer img {
    margin: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
