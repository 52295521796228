.userOpinion {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: #97bf0d;
  box-shadow: 0 0 10px grey;
  border-radius: 15px;
  color: white;
  padding: 10px;
  z-index: 15;
  cursor: pointer;
}

.closeOpinionBox {
  position: absolute;
  width: 20px;
  height: 20px;
  background: white;
  border: none;
  box-shadow: 0 0 20px grey;
  border-radius: 20px;
  top: -10px;
  right: -5px;
  cursor: pointer;
}

.closeOpinionBox i {
  font-size: 9px;
  color: grey;
}

.contentOpinionBox {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

@media only screen and (max-width: 769px) {
  .contentOpinionBox > p {
    display: none;
  }
}

.thumbs {
  font-size: 20px;
}

.thumbs i {
  padding: 10px;
}

.userOpinionModal {
  width: 300px;
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: #f0f0f0;
  box-shadow: 0 0 20px grey;
  border-radius: 15px;
  color: grey;
  padding: 15px;
  z-index: 15;
}

.title {
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 15px;
  width: 300px;
  background: linear-gradient(#97bf0d, #7f9f10);
  border-radius: 15px 15px 0px 0px;
  margin-top: -15px;
  margin-left: -15px;
}

.thumbsZone {
  display: flex;
  justify-content: space-evenly;
  margin: 20px;
}

.thumbsModal {
  display: flex;
  justify-content: space-evenly;
  font-size: 28px;
}

@media only screen and (max-height: 490px) {
  .userOpinionModal {
    height: 250px;
  }
  .thumbsZone {
    display: flex;
    justify-content: space-evenly;
    margin: 10px;
  }
}

.thumbsZone input[type="checkbox"] {
  display: none;
}

.loadcheck {
  width: 165px;
  height: 40px;
  font-size: 35px;
}

.thumbsZone span[class*="entypo"] {
  cursor: pointer;
}

.thumbsZone span[class*="unlike"] {
  color: #ed741e;
  transition: 0.5s;
  transition-delay: 0.1s;
}

.thumbsZone span[class*="check"] {
  color: rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  transition-delay: 0.1s;
}

#check:checked + .loadcheck .entypo-check {
  color: #69b900;
}

#check:checked + .loadcheck .entypo-unlike {
  color: rgba(0, 0, 0, 0.1);
}
