.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
  z-index: 1050;
}

.modal {
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
  max-width: 35%;
  width: 25%;
  z-index: 100;
}

.modalHeader, .modalBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid lightgrey;
}

h3 {
  margin: 0 0 10px 0;
}

.modalInput {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 10px;
}
.modalInput label {
  margin: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.modalInput input {
  margin: 5px 0 0 0;
  background: #ECEDED;
}

@media all and (max-device-width: 480px) {
  .modal {
    max-width: 95%;
    width: 85%;
  }
  .modalInput {
    flex-direction: column;
  }
  .modalInput label {
    margin: 10px;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .modal {
    max-width: 95%;
    width: 65%;
  }
  .modalInput {
    flex-direction: column;
  }
}
@media all and (min-width: 769px) and (max-width: 1280px) {
  .modal {
    max-width: 65%;
    width: 35%;
  }
}

.modalRadio, .modalRadio label {
  margin: 10px;
}
input[type="radio"] {
  filter: invert(0%) hue-rotate(222deg) brightness(1.2);
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.modalCloseButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.modalCloseButton {
  font-size: 1.4rem;
  font-weight: 500;
  color: grey;
  cursor: pointer;
  border: none;
  background: transparent;
}

.errorMessage {
  color: red;
  font-size: 0.85rem;
  text-align: center;
}