body, html {
  margin: 0;
  font-family: Century Gothic, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #6d6d6d !important;
  height: 100%;
  box-sizing: border-box;
  font-size: 90%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
