/*
BUBBLE INFO
*/
.bubble {
  position:relative;
  display: inline-block;
  padding: 20px;
  height: 100%;
  margin: 2em 0 0;
  color: #fff;
  background: #7f9f10; /* default background for browsers without gradient support */
  /* css3 */
  background:-webkit-gradient(linear, 0 0, 0 100%, from(#97bf0d), to(#7f9f10));
  background:-moz-linear-gradient(#97bf0d, #7f9f10);
  background:-o-linear-gradient(#97bf0d, #7f9f10);
  background:linear-gradient(#97bf0d, #7f9f10);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.bubble.left {
  margin-left: 40px;
  width: 50%;
}

.bubble:after {
  content: "";
  position: absolute;
  bottom: -20px; /* value = - border-top-width - border-bottom-width */
  left: 50px; /* controls horizontal position */
  border-width: 20px 0 0 20px; /* vary these values to change the angle of the vertex */
  border-style: solid;
  border-color: #7f9f10 transparent;
  /* reduce the damage in FF3.0 */
  display:block;
  width: 0;
}
.bubble.left:after {
  top: 40px;
  left: -40px; /* value = - border-left-width - border-right-width */
  bottom: auto;
  border-width: 15px 40px 0 0; /* vary these values to change the angle of the vertex */
  border-color: transparent #8fb40a;
}

.coach-icon {
  margin-top: 30px;
  width: 7%;
  height: 7%;
}
@media only screen and (max-width: 768px) {
  .coach-icon {
    margin-top: 40px;
    width: 30%;
    height: 30%;
  }
}


.ask{
  margin: 30px 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .ask {
    margin: 30px auto 30px;
    flex-direction : column;
    align-items: center;
  }
}